import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useCubeQuery } from "@cubejs-client/react";
import DataTable from 'react-data-table-component';
import { Col, Container, Row, Button } from 'react-bootstrap';
import ChartRenderer from "../components/ChartRendererInspection";
import DashboardItem from "../components/DashboardItem";
import { Card } from "antd";

import Grid from "@material-ui/core/Grid";
import Dashboard from "../components/Dashboard";
import {
  Link,
} from "react-router-dom";
import { LeftCircleOutlined } from '@ant-design/icons';
import mockData from './MOCK_DATA.json';

const InspectionTaskList = (props) => {
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [data, setData] = useState([]);
  const [orderby, setOrderBy] = React.useState('desc');
  const [class1, setClass1] = React.useState('');
  const [class2, setClass2] = React.useState('view-active');
  document.title = "Quala Dashboard";

  // const order = (orderby) => {
  //   setOrderBy(orderby);
  //   if(orderby=='asc'){
  //     setClass1('view-active');
  //     setClass2('');
  //   }else{
  //     setClass1('');
  //     setClass2('view-active');
  //   }
  // }

  // getCurrentWeekDate = e => {
  //     var current = new Date;
  //     var firstDayWeek = new Date(current.setDate(current.getDate()-current.getDay()+(current.getDay()==0 ? -6 : 1)));
  //     var start = (firstDayWeek.getYear()+1900)+'-'+(firstDayWeek.getMonth()<9 ? '0'+(firstDayWeek.getMonth()+1) : (firstDayWeek.getMonth()+1))+'-'+(firstDayWeek.getDate()<10 ? '0'+firstDayWeek.getDate() : firstDayWeek.getDate());

  //     var lastDayWeek = new Date(firstDayWeek.getTime() + (6 * 24 * 60 * 60 * 1000));
  //     var last = (lastDayWeek.getYear()+1900)+'-'+(lastDayWeek.getMonth()<9 ? '0'+(lastDayWeek.getMonth()+1) : (lastDayWeek.getMonth()+1))+'-'+(lastDayWeek.getDate()<10 ? '0'+lastDayWeek.getDate() : lastDayWeek.getDate());
  //     var dateArr=[];
  //     dateArr.push(start);
  //     dateArr.push(last);
  //     return dateArr;
  //   }

  //   getFirstWeekDate = e => {
  //     var current = new Date;
  //     var firstDayWeek = new Date(current.setDate(current.getDate()-current.getDay()+(current.getDay()==0 ? -6 : 1)));
  //     var start = (firstDayWeek.getYear()+1900)+'-'+(firstDayWeek.getMonth()<9 ? '0'+(firstDayWeek.getMonth()+1) : (firstDayWeek.getMonth()+1))+'-'+(firstDayWeek.getDate()<10 ? '0'+firstDayWeek.getDate() : firstDayWeek.getDate());

  //     //var lastDayWeek = new Date(firstDayWeek.getTime() + (6 * 24 * 60 * 60 * 1000));
  //     //var last = (lastDayWeek.getYear()+1900)+'-'+(lastDayWeek.getMonth()<9 ? '0'+(lastDayWeek.getMonth()+1) : (lastDayWeek.getMonth()+1))+'-'+(lastDayWeek.getDate()<10 ? '0'+lastDayWeek.getDate() : lastDayWeek.getDate());
  //     var dateArr=[];
  //     dateArr.push(start);
  //     return dateArr;
  //   }

  //   getLastWeekDate = e => {
  //     var current = new Date;
  //     var firstDayWeek = new Date(current.setDate(current.getDate()-current.getDay()+(current.getDay()==0 ? -6 : 1)));
  //     var start = (firstDayWeek.getYear()+1900)+'-'+(firstDayWeek.getMonth()<9 ? '0'+(firstDayWeek.getMonth()+1) : (firstDayWeek.getMonth()+1))+'-'+(firstDayWeek.getDate()<10 ? '0'+firstDayWeek.getDate() : firstDayWeek.getDate());

  //     var lastDayWeek = new Date(firstDayWeek.getTime() + (6 * 24 * 60 * 60 * 1000));
  //     var last = (lastDayWeek.getYear()+1900)+'-'+(lastDayWeek.getMonth()<9 ? '0'+(lastDayWeek.getMonth()+1) : (lastDayWeek.getMonth()+1))+'-'+(lastDayWeek.getDate()<10 ? '0'+lastDayWeek.getDate() : lastDayWeek.getDate());
  //     var dateArr=[];
  //     dateArr.push(last);
  //     return dateArr;
  //   }


  useEffect(() => {
    console.log(props)
    console.log('props.match.params.param1', props.match.params.param1);
    document.title = props.match.path;
    switch (props.match.params.param2) {
      case 'pending-orders':
        setData('pending-orders');
        //setQueryText('PendingWorkOrder.pending_count');
        break;

      case 'delayed-orders':
        setData('delayed-orders');
        //setQueryText('DelayedSOrder.delayed_gt_48_count');
        break;

      case 'missed-po-order':
        setData('missing-orders');
        //setQueryText('MissedSOrder.missing_po_count');
        break;

      default:
        setData(mockData);

    }

  }, props.match.params.param2);
  const qry = [];
  const rangeVal = []
  React.useMemo(() => {
    const dimension1 = props.match.params.param4 == "Inspection Type" ? "Inspection.inspection_type" : props.match.params.param4 == "Bay" ? "Inspection.bay" : "";
    const type = props.match.params.param2 == "pending" ? "notSet" : props.match.params.param2 == "completed" ? "set" : "";
    //const rangeVal=props.match.params.param3=="this week" ? this.getCurrentWeekDate : props.match.params.param3=="prev week" ? this.getFirstWeekDate : props.match.params.param3=="next week" ? this.getLastWeekDate : "";
    const rangeOpera = props.match.params.param3 == "this week" ? "inDateRange" : props.match.params.param3 == "prev week" ? "beforeDate" : props.match.params.param3 == "next week" ? "afterDate" : "";
    if (props.match.params.param3 == "prev week") {
      var current = new Date;
      var firstDayWeek = new Date(current.setDate(current.getDate() - current.getDay() + (current.getDay() == 0 ? -6 : 1)));
      var start = (firstDayWeek.getYear() + 1900) + '-' + (firstDayWeek.getMonth() < 9 ? '0' + (firstDayWeek.getMonth() + 1) : (firstDayWeek.getMonth() + 1)) + '-' + (firstDayWeek.getDate() < 10 ? '0' + firstDayWeek.getDate() : firstDayWeek.getDate());

      //var lastDayWeek = new Date(firstDayWeek.getTime() + (6 * 24 * 60 * 60 * 1000));
      //var last = (lastDayWeek.getYear()+1900)+'-'+(lastDayWeek.getMonth()<9 ? '0'+(lastDayWeek.getMonth()+1) : (lastDayWeek.getMonth()+1))+'-'+(lastDayWeek.getDate()<10 ? '0'+lastDayWeek.getDate() : lastDayWeek.getDate());
      //var dateArr=[];
      rangeVal.push(start);
      //return dateArr;
    } else if (props.match.params.param3 == "this week") {
      var current = new Date;
      var firstDayWeek = new Date(current.setDate(current.getDate() - current.getDay() + (current.getDay() == 0 ? -6 : 1)));
      var start = (firstDayWeek.getYear() + 1900) + '-' + (firstDayWeek.getMonth() < 9 ? '0' + (firstDayWeek.getMonth() + 1) : (firstDayWeek.getMonth() + 1)) + '-' + (firstDayWeek.getDate() < 10 ? '0' + firstDayWeek.getDate() : firstDayWeek.getDate());

      var lastDayWeek = new Date(firstDayWeek.getTime() + (6 * 24 * 60 * 60 * 1000));
      var last = (lastDayWeek.getYear() + 1900) + '-' + (lastDayWeek.getMonth() < 9 ? '0' + (lastDayWeek.getMonth() + 1) : (lastDayWeek.getMonth() + 1)) + '-' + (lastDayWeek.getDate() < 10 ? '0' + lastDayWeek.getDate() : lastDayWeek.getDate());
      //var dateArr=[];
      rangeVal.push(start);
      rangeVal.push(last);
      //return dateArr;
    } else if (props.match.params.param3 == "next week") {
      var current = new Date;
      var firstDayWeek = new Date(current.setDate(current.getDate() - current.getDay() + (current.getDay() == 0 ? -6 : 1)));
      var start = (firstDayWeek.getYear() + 1900) + '-' + (firstDayWeek.getMonth() < 9 ? '0' + (firstDayWeek.getMonth() + 1) : (firstDayWeek.getMonth() + 1)) + '-' + (firstDayWeek.getDate() < 10 ? '0' + firstDayWeek.getDate() : firstDayWeek.getDate());

      var lastDayWeek = new Date(firstDayWeek.getTime() + (6 * 24 * 60 * 60 * 1000));
      var last = (lastDayWeek.getYear() + 1900) + '-' + (lastDayWeek.getMonth() < 9 ? '0' + (lastDayWeek.getMonth() + 1) : (lastDayWeek.getMonth() + 1)) + '-' + (lastDayWeek.getDate() < 10 ? '0' + lastDayWeek.getDate() : lastDayWeek.getDate());
      //var dateArr=[];
      rangeVal.push(last);
      //return dateArr;
    }
    console.log('ss', props.match.params.param4)
    qry.push({
      id: 0,
      name: "Widget List",
      vizState: {
        query: {
          "measures": [
            "Inspection.count"
          ],
          "filters": [{
            "dimension": "AdOrg.facility",
            "operator": "equals",
            "values": [props.match.params.param1]
          }, {
            "dimension": "Inspection.enddate",
            "operator": type
          }, {
            "dimension": "Inspection.startdate",
            "operator": rangeOpera,
            "values": rangeVal
          }, {
            "dimension": dimension1,
            "operator": "equals",
            "values": [props.match.params.param5]
          }
          ],
          "dimensions": ["Inspection.bay", "Inspection.inspection_type", "Inspection.documentno", "Inspection.startdate"]
        },
        chartType: "inspection"
      },
      grid: 12
    });
  })
  console.log(qry);
  console.log(qry[0].vizState)
  return (<Dashboard>
    <div class="row page-header">
      <div class="col-md-1 col-2 backicon">
        <Link style={{ textDecoration: "none" }} to={`/`}>
          <LeftCircleOutlined style={{ fontSize: '50px', background: '#307a49', borderRadius: '70px', marginLeft: '15px', marginTop: '4px', color: 'white' }} />
        </Link>
      </div>
      <div class="col-md-7 col-10">
        <h3  className="list-head-title">{props.match.params.param2 + " - " + props.match.params.param4}</h3>
        <h3 className="list-head-sub-title">{props.match.params.param5}</h3>
      </div>
    </div>
    <Grid className='top-widgets' xs={24} lg={24} container spacing={0} style={{ padding: 0 }} justify="left" alignItems="flex-start">

      <ChartRenderer vizState={qry[0].vizState} />
    </Grid>
  </Dashboard>)
}
export default InspectionTaskList;







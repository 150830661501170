import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import 'bootstrap/dist/css/bootstrap.min.css';
import * as serviceWorker from "./serviceWorker";
import { HashRouter as Router, Route } from "react-router-dom";
// import DashboardPage from "./pages/DashboardPage";
// import CustomerDashboardPage from "./pages/DashboardPage_Customer";
// import DashboardPageGlobal from "./pages/DashboardPageGlobal";
// import DashboardPageGlobalOldUI from "./pages/DashboardPageGlobalOldUI";
// import ManagerDashboardPage from "./pages/DashboardPage_Manager";
// import ManagerDashboardPageDrill from "./pages/DashboardPage_Manager_Drill_Down";

// import ManagerDashboardPageLatest from "./pages/DashboardPage_Manager_latest";

// import NeedsAction from "./pages/NeedsAction";
// import ListTable from './components/ListTable';
// import ListWidget from './components/ListWidget';
// import ListTables1 from './components/ListTables1';
// import ListTables2 from './components/ListTables2';
import Inspection from './components/Inspection';
import InspectionTaskList from './components/InspectionTaskList';
import InspectionTaskDetail from './components/InspectionTaskDetail';


// ReactDOM.render(
//   <DashboardPage />,
//   document.getElementById("root")
// );

ReactDOM.render(
  // <React.StrictMode>
  <Router>
    <App>
      {/* <Route key="index" exact path="/global" component={DashboardPageGlobalOldUI} />
      <Route key="index" exact path="/global-old" component={DashboardPageGlobal} />
      <Route key="index" exact path="/customer" component={CustomerDashboardPage} />
      <Route key="index" exact path="/manager" component={ManagerDashboardPage} /> 
      <Route key="index" exact path="/drill" component={ManagerDashboardPageDrill} />*/}
      {/* <Route key="index" exact path="/" component={ManagerDashboardPageLatest} /> */}
      {/* <Route key="index" exact path="/" component={NeedsAction} /> */}
      <Route key="index" exact path="/" component={Inspection} />
      <Route key="index" exact path="/inspection-task-list/facility=:param1&type=:param2&dateRange=:param3&groupBy=:param4&value=:param5" render={(props) => <InspectionTaskList filter={'date'} {...props}/>} title="Link 1" />
      <Route key="index"  path="/inspection-task-detail/ins_number=:param1" render={(props) => <InspectionTaskDetail   {...props}/>} title="Link 1" />
      
     
     
      {/* <Route exact path="/needs-action/status=success" render={(props) => <ListTable filter={'success'} {...props}/>} title="Link 1"/> */}
      {/* <Route   path="/needs-action/facility=:param1&report=:param2" render={(props) => <ListTable filter={'date'} {...props}/>} title="Link 2" /> */}
      {/* <Route   path="/needs-action/facility=:param1&report=:param2&viewBy=:param3" render={(props) => <ListWidget filter={'date'} {...props}/>} title="Link 2" />
      <Route   path="/needs-action-details/facility=:param1&report=:param2&value=:param3&viewBy=:param4" render={(props) => <ListTables2 filter={'date'} {...props}/>} title="Link 1" />
      <Route   path="/needsactiondetails/facility=:param1&report=:param2" render={(props) => <ListTables1 filter={'date'} {...props}/>} title="Link 3" /> */}
      {/* <Route   path="/needsactiondetails/facility=:param1&report=:param2&value=:param3&viewBy=:param4" render={(props) => <ListTables1 filter={'date'} {...props}/>} title="Link 3" /> */}
    
    </App>
  </Router>,
  // </React.StrictMode>,
  document.getElementById("root")
);





// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.register();

import React, { PureComponent } from "react";
import { Row, Col, Card, PageHeader, Button, Spin, Modal } from "antd";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import Dashboard from "../components/Dashboard";
import DashboardItem from "../components/DashboardItem";
import { Link } from "react-router-dom";
import tickImage from "../components/success.png";
import { LeftCircleOutlined } from '@ant-design/icons';

// import Tooltip from '@bit/recharts.recharts.tooltip';
import "antd/dist/antd.css";

class InspectionTaskDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      facilityList: ["101"],
      inspectionNumber: "INSP1000027",
      textName: "textName",
      textArea: "textArea",
      taskData: [],
      docNo: '',
      load: false,
      isSubmitted: false,
      visible: false
    };
  }

  componentDidMount() {
    this.setState({ docNo: this.props.match.params.param1, load: true });
    axios({
      method: "POST",
      url:
        "https://obdevportal.quala.us.com/portalapi/api/getinspectdocdetail",
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJqb2huZmFuZGxNb24gQXByIDIwIDEyOjU1OjM3IElTVCAyM.DIwIiwidXNlck5hbWUiOiJqb2huZmFuZGxNb24gQXByIDIwIDEyOjU1OjM3IElTVCAyMDIwIn0.2Ey-YI8XAeJe8bhJE0-9Eto0hK-XzFrPZwlGNKEy7_PRssGc9MbQmssRXvOEGVREwUCkIFo6ZBX4paC356xF8A",
      },
      data: {
        "docNo": this.props.match.params.param1
      },
    }).then((res) => {
      let taskData = res.data.fields;
      this.setState({ taskData: [], load: false });
      this.setState({ taskData: taskData, load: false });
      console.log('taskData ONLOAD', this.state.taskData);

    });
  }

  // componentDidUpdate() {
  //   this.setState({ docNo: this.props.match.params.param1 });
  //   axios({
  //     method: "POST",
  //     url:
  //       "https://obdevportal.quala.us.com/portalapi/api/getinspectdocdetail",
  //     headers: {
  //       Authorization:
  //         "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJqb2huZmFuZGxNb24gQXByIDIwIDEyOjU1OjM3IElTVCAyM.DIwIiwidXNlck5hbWUiOiJqb2huZmFuZGxNb24gQXByIDIwIDEyOjU1OjM3IElTVCAyMDIwIn0.2Ey-YI8XAeJe8bhJE0-9Eto0hK-XzFrPZwlGNKEy7_PRssGc9MbQmssRXvOEGVREwUCkIFo6ZBX4paC356xF8A",
  //     },
  //     data: {
  //       docNo: this.props.match.params.param1
  //     },
  //   }).then((res) => {
  //     let taskData = res.data.fields;
  //     this.setState({ taskData });
  //    console.log('taskData ONLOAD', this.state.taskData);

  //   });
  // }


  // handleChange = (e) => {
  //   let taskDataArray = this.state.taskData;
  //   taskDataArray.map((task, i) => {
  //     if (task.lineId[i] == e.target.getAttribute("dataIdentifier")) {
  //       if (e.target.getAttribute("inputIdentifier") == "inspection_res") {
  //         // task.value = e.target.value;
  //         alert();
  //         this.state.taskData[i].value = e.target.value; 
  //       } else if (e.target.getAttribute("inputIdentifier") == "pass_ins") {
  //         if (e.target.checked == true) {
  //           task.passed = "Y";
  //         } else {
  //           task.passed = "N";
  //         }
  //       } else if (e.target.getAttribute("inputIdentifier") == "notes") {
  //         task.notes = e.target.value;
  //       }
  //     }
  //   });
  //   this.setState({ taskData: taskDataArray }); 
  // };




  handleChange = (e) => {
    let taskDataArray = this.state.taskData;
    taskDataArray.map((task) => {
      if (task.lineId == e.target.getAttribute("dataIdentifier")) {
        if (e.target.getAttribute("inputIdentifier") == "inspection_res") {
          task.value = e.target.value;
        } else if (e.target.getAttribute("inputIdentifier") == "pass_ins") {
          if (e.target.checked == true) {
            task.passed = "Y";
          } else {
            task.passed = "N";
          }
        } else if (e.target.getAttribute("inputIdentifier") == "notes") {
          task.notes = e.target.value;
        }
      }
    });
    this.setState({ taskData: taskDataArray });
    console.log(this.state.taskData);
  };

  handleSubmit = event => { 

    this.setState({ load: true });
    event.preventDefault(); 
    axios({
      method: "POST",
      url: "https://obdevportal.quala.us.com/portalapi/api/updateinspectionline",
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJqb2huZmFuZGxNb24gQXByIDIwIDEyOjU1OjM3IElTVCAyM.DIwIiwidXNlck5hbWUiOiJqb2huZmFuZGxNb24gQXByIDIwIDEyOjU1OjM3IElTVCAyMDIwIn0.2Ey-YI8XAeJe8bhJE0-9Eto0hK-XzFrPZwlGNKEy7_PRssGc9MbQmssRXvOEGVREwUCkIFo6ZBX4paC356xF8A",
      },
      data: {
        userId: "06D982A3B60243BC9AAC1C747D9CAA08",
        docNo: this.state.docNo,
        fields: this.state.taskData
      },
    })
      .then(response => {
        console.log(response);
        this.setState({ isSubmitted: true, load: false, visible: true });

      })
      .catch(error => {
        console.log(error)
      })
  }

  render() {
    return (
      <Dashboard>

        <div class="row page-header">
          <div class="col-md-1 col-2 backicon">
            <Link style={{ textDecoration: "none" }} to={`/`}>
              <LeftCircleOutlined style={{ fontSize: '50px', background: '#307a49', borderRadius: '70px', marginLeft: '15px', marginTop: '4px', color: 'white' }} />
            </Link>
          </div>
          <div class="col-md-7 col-10">
            <h3 className="list-head-title"> Inspection Detail</h3>
            <h3 className="list-head-sub-title" style={{ color: "#707070", fontSize: "20px" }}>   {this.state.docNo}{" "} </h3>
          </div>
        </div> 

        <Grid
          className="top-widgets"
          xs={24}
          lg={24}
          container
          spacing={0}
          style={{ padding: 0 }}
          justify="left"
          alignItems="flex-start"
        >
          <Col span={24} md={12} lg={12} xs={24}>
            <DashboardItem>
              <Row type="flex" justify="center" align="middle">
                <Col span={24} md={24} lg={24} xs={24}>
                  <Spin spinning={this.state.load}>
                    {this.state.isSubmitted &&
                      <div className='success-section'>
                        <Modal
                          visible={this.state.visible}
                          onOk={this.handleOk}
                          onCancel={this.handleCancel}
                          footer={null}
                          header={null}
                        >
                          <center>
                            <img src={tickImage} alt="tickImage" width='150' /> <br /> <br /> <br />
                            <h4> Inspection has been completed! </h4> <br />
                            <Link to='/'>
                              <span className="nav-button"> Back to Inspection </span>
                              <br />
                              <br />
                            </Link>
                          </center>
                        </Modal>
                      </div>

                    }

                    {this.state.isSubmitted === false && (
                      <form className="task-detail-form" onSubmit={this.handleSubmit} className="well">
                        {this.state.taskData.map((taskData, i) => (
                          <div>
                            <h4 class="task-title"> {taskData.label}  - <span className="small-text">{taskData.lineId}</span></h4>
                            <Row type="flex" justify="left" align="middle">
                              <Col span={12} md={12} lg={12} xs={24}>
                                <div className="form-group">
                                  <label className="form-balel"> Inspection Result* </label>{" "}
                                  <input
                                    type="number"
                                    required
                                    className="form-control"
                                    inputIdentifier="inspection_res"
                                    dataIdentifier={taskData.lineId}
                                    onChange={this.handleChange}
                                    defaultValue={this.state.taskData[i].value}
                                  />
                                </div>
                              </Col>
                              <Col span={12} md={12} lg={12} xs={24}>
                                <div className="form-group">
                                  <label className="form-balel">Failed Inspection?</label> <br />
                                  <input
                                    type="checkbox"
                                    inputIdentifier="pass_ins"
                                    dataIdentifier={taskData.lineId}
                                    onChange={this.handleChange}
                                    onLoad={this.handleCheck}
                                    defaultChecked={
                                      taskData.passed != "N" ? true : false
                                    }
                                  />
                                </div>
                              </Col>
                              <Col span={24} md={24} lg={24} xs={24}>
                                <div className="form-group">
                                  <label className="form-balel">Notes</label>
                                  <textarea
                                    className="form-control"
                                    rows="3"
                                    // value={this.state.taskData[i].notes}
                                    inputIdentifier="notes"
                                    dataIdentifier={taskData.lineId}
                                    onChange={this.handleChange}
                                  >
                                    {this.state.taskData[i].notes}
                                  </textarea>
                                </div>
                              </Col>
                              <Col span={24} md={24} lg={24} xs={24}>
                                <hr />
                              </Col>
                            </Row>
                          </div>
                        ))}
                        <button type="submit" className="btn-submit scheduled-btn  view-active" >  Submit  </button>
                        <Link to="/"> <button type="button" className="btn-cancel"> Cancel </button> </Link>
                      </form>
                    )}
                  </Spin>

                </Col>
              </Row>
            </DashboardItem>
          </Col>
        </Grid>
      </Dashboard>
    );
  }
}

export default InspectionTaskDetail;

import React from "react"; 
//import "./App.css";
// import "./App_Needs.css"; 
import "./App_Inspection.css";

// import "./App_v2.css";
import "./body.css";
import "antd/dist/antd.css";
import { Layout } from "antd";
import cubejs from "@cubejs-client/core";
import { CubeProvider } from "@cubejs-client/react";
import Header from "./components/Header";

// const API_URL = "http://localhost:4000";
// const CUBEJS_TOKEN =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.49b779a328990acd354ebacb201bbeafc7bb394e94de2badb3dbe5075a87226eb7efa78ed59b1ce86a9216b592f40e04f57115c50d358fe4a9ea4edd0a05af1b";
// const cubejsApi = cubejs(CUBEJS_TOKEN, {
//   apiUrl: `${API_URL}/cubejs-api/v1`
// });
 
const API_URL = "https://obdev.quala.us.com:4433";
const CUBEJS_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE1OTQ2NTEyODksImV4cCI6MTU5NDczNzY4OX0.zYGywmDejd8cjezlyQ5brFDlfoBcpuy-TtIw8SzyNgo";
const cubejsApi = cubejs(CUBEJS_TOKEN, {
  apiUrl: `${API_URL}/cubejs-api/v1`
});


// const API_URL = "https://obpreprod.quala.us.com/qualacube/";
// const CUBEJS_TOKEN =
//   "1c815a3d8ae957b303850a15d40647ea3aaa5f91478a783f00b2aaa07308fb7a90dc50e64378ec323357d9ffa854a8b56cb17edbea50c927b438cdb8446a89c6";
// const cubejsApi = cubejs(CUBEJS_TOKEN, {
//   apiUrl: `${API_URL}/cubejs-api/v1`
// });


// Production 

// const API_URL = "https://obpreprod.quala.us.com/qualacube/";
// const CUBEJS_TOKEN =
//   "1c815a3d8ae957b303850a15d40647ea3aaa5f91478a783f00b2aaa07308fb7a90dc50e64378ec323357d9ffa854a8b56cb17edbea50c927b438cdb8446a89c6";
// const cubejsApi = cubejs(CUBEJS_TOKEN, {
//   apiUrl: `${API_URL}/cubejs-api/v1`
// });





// comment added
 
const AppLayout = ({ children }) => (
  <Layout
    style={{
      height: "100%"
    }}
  >
    <Header />
    <Layout.Content>{children}</Layout.Content>
  </Layout>
);

const App = ({ children }) => (
  <CubeProvider cubejsApi={cubejsApi}>
    <AppLayout>{children}</AppLayout>
  </CubeProvider>
);

export default App;

import React from "react";
import PropTypes from "prop-types";
import { useCubeQuery } from "@cubejs-client/react";
import { Spin, Row, Col, Statistic, Table, Collapse } from "antd";
import Grid from "@material-ui/core/Grid";
import Dashboard from "../components/Dashboard";
import DashboardItem from "../components/DashboardItem";
import 'antd/dist/antd.css';
import { createFromIconfontCN } from '@ant-design/icons';
import {
  Link,
} from "react-router-dom";
import {
  CartesianGrid,
  PieChart,
  Pie,
  Cell,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  BarChart,
  Bar,
  LineChart,
  Line,
  Label
} from "recharts";

import moment from "moment";
const { Panel } = Collapse;
const dateFormatter = item => moment(item).format("MMM 'YY");
const dateDayFormatter = item => moment(item).format("MMM DD 'YY");
const dateTimeFormatter = item => moment(item).format("MMM DD 'YY hh:mm:ss a");
// const dateTimeFormat = "MMM 'YY";
// const dateFormatter = item => moment(item).format(dateTimeFormat);
const emptyFormatter = item => item != "" ? item : "None";

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js',
});

const genExtra = (orderId) => (
  <IconFont type="icon-tuichu" 
    onClick={event => {
      window.open('https://openbravo.quala.us.com/openbravo/?tabId=186&recordId='+orderId)
      event.stopPropagation();
    }}
  />
);

const delayRedir = (wodocno,woorderid,sorderid) => (
  <IconFont type="icon-tuichu" 
    onClick={event => {
      if(wodocno != null || woorderid != null){
        window.open("https://openbravo.quala.us.com/openbravo/?tabId=AB955F8DD46E4F308199E14B13AECAE5&recordId="+woorderid);
      }else{
        window.open("https://openbravo.quala.us.com/openbravo/?tabId=186&recordId="+sorderid);
      }
      event.stopPropagation();
    }}
  />
);

const pendRedir = (wodocno,wosdocno,woorderid) => (
  <IconFont type="icon-tuichu" 
    onClick={event => {
      if(wodocno != null){
        window.open("https://openbravo.quala.us.com/openbravo/web/com.agilityerp.openbravo.mobile.quala.yardcheck/#wo-workorder?documentno="+wodocno);
      }else if(wosdocno != null){
        window.open("https://openbravo.quala.us.com/openbravo/?tabId=186&recordId="+woorderid);
      }
      event.stopPropagation();
    }}
  />
);

const convertToFormalHours = function (totalSeconds) {
  let negative;
  // Find a Negative number
  if (totalSeconds < 0) { negative = true } else { negative = false }
  if (negative) { totalSeconds = totalSeconds * -1; }
  var hours = Math.floor(totalSeconds / 3600);
  var minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
  var seconds = totalSeconds - (hours * 3600) - (minutes * 60);
  // round seconds
  seconds = Math.round(seconds * 100) / 100
  var result = (hours < 10 ? "0" + hours : hours);
  result += ":" + (minutes < 10 ? "0" + minutes : minutes);
  result += ":" + (seconds < 10 ? "0" + seconds : seconds);
  if (negative) { return result = '-' + result } else { return result };
}

const CartesianChart = ({ resultSet, children, ChartComponent }) => (
  <ResponsiveContainer width="100%" height={150}>
    <ChartComponent data={resultSet.chartPivot()} cursor="pointer">
      {/* <XAxis dataKey="x" /> */}

      {/* {console.log('resultSet =', resultSet.loadResponse.query.timeDimensions[0].granularity)}



      {(() => {
        if (resultSet.loadResponse.query.timeDimensions[0].granularity == 'hour') {
          return (
            <XAxis dataKey="x" tickFormatter={dateTimeFormatter} />
          )
        } else if (resultSet.loadResponse.query.timeDimensions[0].granularity == 'day') {
          return (
            <XAxis dataKey="x" tickFormatter={dateDayFormatter} />
          )
        } else {
          return (
            <XAxis dataKey="x" tickFormatter={dateFormatter} />
          )
        }
      })()} */}
{/* 

      { resultSet.loadResponse.query.timeDimensions[0].granularity == 'hour' ? ( 
      <XAxis dataKey="x" tickFormatter={dateTimeFormatter} /> ) : 
      (<XAxis dataKey="x" tickFormatter={dateFormatter} />) } */}

      {console.log(resultSet.chartPivot())}
      <XAxis dataKey="xValues[0]" tick={{fontSize: 12}} interval={0} tickFormatter={emptyFormatter}/>
      {/* <YAxis /> */}
      {children}
      <Legend />
      <Tooltip cursor={false} />
    </ChartComponent>
  </ResponsiveContainer>
);

const colors = ["#9493DC", "#F7855E", "#BDC5CD", '#9A97A7', '#2A334A', "#6DAC4FFF", "#006B38FF", "#97BC62FF", "#2C5F2D", "#214a32", '#8cc63f', "#17703c", "#d5e4c0"];

const getPath = (x, y, width, height) => {
  return `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + 2 * width / 3},${y + height} ${x + width}, ${y + height}
          Z`;
};

 
const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

TriangleBar.propTypes = {
  fill: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};



const stackedChartData = resultSet => {
  const data = resultSet
    .pivot()
    .map(({ xValues, yValuesArray }) =>
      yValuesArray.map(([yValues, m]) => ({
        x: resultSet.axisValuesString(xValues, ", "),
        color: resultSet.axisValuesString(yValues, ", "),
        measure: m && Number.parseFloat(m)
      }))
    )
    .reduce((a, b) => a.concat(b), []);
  return data;
};

const TypeToChartComponent = {
  line: ({ resultSet }) => (
    <CartesianChart resultSet={resultSet} ChartComponent={LineChart}>
      {resultSet.seriesNames().map((series, i) => (
        <Line
          key={series.key}
          stackId="a"
          dataKey={series.key}
          name={series.title}
          stroke={colors[i]}
        />
      ))}
    </CartesianChart>
  ),
  bar: ({ resultSet }) => (
    <CartesianChart resultSet={resultSet} ChartComponent={BarChart}>
      {/* {console.log('resultSet',resultSet)} */}
      {resultSet.seriesNames().map((series, i) => (
        // <Bar
        //   key={series.key}
        //   shape={<TriangleBar/>} 
        //   stackId="a"
        //   dataKey={series.key}
        //   stroke={colors[i]}
        //   name={series.title}
        //   fill={colors[i]}
        //   background={{ fill: '#eee' }}
        // /> 
         
        <Bar
          key={series.key}
          stackId="a"
          dataKey={series.key}
          name={series.title.replace(/^Work Order|^S Order/g, '')}
          fill="#2d703c"
        />
      ))}
    </CartesianChart>
  ),
  minibar: ({ resultSet }) => (
    <CartesianChart resultSet={resultSet} ChartComponent={BarChart}>
      {resultSet.seriesNames().map((series, i) => ( 
        <Bar 
          layout = "vertical"
          label={{ position: 'center', fill:'#ffffff' }}
          key={series.key}
          stackId="a"
          dataKey={series.key}
          name={series.title.replace(/^Pending Work Order|^S Order/g, '').replace(/^Delayed S Order|^S Order/g, '').replace(/^Missed S Order Missing Po Count|^S Order/g, 'Missing PO Counts')}
          fill="#2d703c"
          barSize={40} 
          background={{fill: "#FFF"}}
        /> 
      ))}
    </CartesianChart>
  ),
  area: ({ resultSet }) => (
    <CartesianChart resultSet={resultSet} ChartComponent={AreaChart}>
      {resultSet.seriesNames().map((series, i) => (
        <Area
          key={series.key}
          stackId="a"
          dataKey={series.key}
          name={series.title}
          stroke={colors[i]}
          fill={colors[i]}
        />
      ))}
    </CartesianChart>
  ),
  pie: ({ resultSet }) => (
    <ResponsiveContainer width="100%" height={350}>
      {resultSet.seriesNames()[0] != undefined ? (<PieChart>
        <Pie
          isAnimationActive={false}
          data={resultSet.chartPivot()}
          nameKey="x"
          dataKey={resultSet.seriesNames()[0] == undefined ? (0) : (resultSet.seriesNames()[0].key)}
          // label
          label={(entry)=> entry.name +' :  '+ entry.value }
        >
          {resultSet.chartPivot().map((e, index) => (
            <Cell key={index} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        {/* <Legend /> */}
        <Tooltip />
      </PieChart>) : (<Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: "100%"
        }}
      >
        <Col> <Statistic value={0} /> </Col> </Row>)}
    </ResponsiveContainer>
  ),
  number: ({ resultSet }) => (
    <Row type="flex" justify="center" align="middle" style={{ height: "100%" }}>
      <Col>
        {
          resultSet.seriesNames().map(s => {
            let chartValue = resultSet.totalRow()[s.key];
            let filterFormat = resultSet.loadResponse.annotation.measures;

            let formatSpec = ' ';
            if (filterFormat["SOrder.cancelled_percent"] || filterFormat["WorkOrder.late_percent"]) {
              formatSpec = "%";
            }
            if (filterFormat["WorkOrder.avg_work_order_duration_hours"] || filterFormat["WorkOrder.avg_work_order_lead_hours"]) {
              if (chartValue % 1 === 0) { chartValue = chartValue } else { chartValue = Math.trunc(chartValue) }
              chartValue = convertToFormalHours(chartValue);
              // return <Statistic className='big-number' value={chartValue + formatSpec} />
              return <div className='big-number'> {chartValue + formatSpec} </div>
           
            } else {
              if(filterFormat["COrder.bookedRevenue"] || filterFormat["PendingWorkOrder.pending_order_total"] || filterFormat["DelayedSOrder.delayed_gt_48_total"] || filterFormat["MissedSOrder.missing_po_total"] ){
                //return <Statistic value={"$ "+ (Math.round(chartValue)).toLocaleString('en') + "of $ revenue value"} />
                return  <div className='text-typo-2'> {"$ "+ (Math.round(chartValue)).toLocaleString('en')} <span className='small-word'> of $ revenue value </span> </div>
                //return
              
              }
              if(filterFormat["MTDRevenue.grandtotal"]){
                //return <Statistic value={"$ "+ (Math.round(chartValue)).toLocaleString('en') + "of $ revenue value"} />
                //return  <div className='text-typo-2'> {"$ "+ (Math.round(chartValue)).toLocaleString('en')} <span className='small-word'> of $ revenue value </span> </div>
                return <div className='big-number'> {"$ "+ (Math.round(chartValue)).toLocaleString('en')} </div>
                //return
              
              }
              if (typeof chartValue === 'number') {
                if (chartValue % 1 === 0) {
                //  return <Statistic value={chartValue + formatSpec} />
                  return <div className='big-number'> {chartValue + formatSpec} </div>
                } else {
                 // return <Statistic value={chartValue.toFixed(2) + "" + formatSpec} />
                  return <div className='big-number'> {chartValue.toFixed(2) + "" + formatSpec} </div>
                }
              } else {
                return <div className='big-number'>{Math.round(chartValue)}</div>
              }
            }


          })

        }
      </Col>
    </Row>
  ),
  table: ({ resultSet, pivotConfig }) => (
    <Table
      pagination={true}
      columns={resultSet.tableColumns(pivotConfig)}
      dataSource={resultSet.tablePivot(pivotConfig)}
    />
  ),
  widget: ({ resultSet ,facility,type,dateRange,groupBy}) => (
    
    resultSet.loadResponse.data.map(s => {
      let pendingCount = s[resultSet.loadResponse.query.measures[0]];
      let grandTotal = s[resultSet.loadResponse.query.measures[1]];
      console.log(s[resultSet.loadResponse.query.dimensions[0]])
      console.log({resultSet})
    //   let dimension1=viewBy=="product-name" ? "Product.name" : viewBy=="customers" ? "CBpartner.name" : viewBy=="scheduled-bay" ? (report=="pending-orders" ? "PendingWorkOrder.scheduled_bay" : report=="delayed-orders" ? "DelayedSOrder.scheduled_bay" : "") : "";
    //   let dimension2=report=="pending-orders" ? "PendingWorkOrder.wo_status" : report=="delayed-orders" ? "DelayedSOrder.wo_status" : "";
    //   let managerFacilityFilter = [{
    //     "dimension": "AdOrg.facility",
    //     "operator": "equals",
    //     "values": [facility]
    //   },{
    //     "dimension": resultSet.loadResponse.query.dimensions[0],
    //     "operator": s[resultSet.loadResponse.query.dimensions[0]] != "null" ? "equals" : "notSet",
    //     "values": [s[resultSet.loadResponse.query.dimensions[0]]]
    //   }
    //   ];
    //   const newQry=[{
    //     id: 0,
    //     name: "List Widget and Status",
    //     isVisible:true,
    //     vizState: {
    //       query: {
    //         "measures": [resultSet.loadResponse.query.measures[0]],
    //         "filters": managerFacilityFilter,
    //         "dimensions":[dimension2,dimension1]
    //       },
    //       chartType: "minibar"
    //     },
    //     grid: 12
    //   }]
      return(
      <Col span={24} md={12} lg={12} xs={24}>
      <Link style={{textDecoration:"none"}} to={`/inspection-task-list/facility=${facility}&type=${type}&dateRange=${dateRange}&groupBy=${groupBy}&value=${s[resultSet.loadResponse.query.dimensions[0]]}`}>
      <DashboardItem title={s[resultSet.loadResponse.query.dimensions[0]] != null ? s[resultSet.loadResponse.query.dimensions[0]] : "None"}>
      <Row type="flex" justify="center" align="middle" style={{ height: "100%" }}>
      <Col>
              <div className='big-number'>{Math.round(pendingCount)}</div>
      </Col>
      </Row>
      {/* <Row type="flex" justify="center" align="middle" style={{ height: "100%" }}>
      <Col>
              <div className='text-typo-2'> {"$ "+ (Math.round(grandTotal)).toLocaleString('en')} <span className='small-word'> of $ revenue value </span> </div>
      </Col>
      </Row> */}
      {/* <Row className="status-chart" justify="center" style={{ height: "100%"}}><ChartRenderer vizState={newQry[0].vizState} /></Row> */}
            </DashboardItem>
            </Link>
        </Col>)
    })
  ),
  inspection: ({ resultSet ,facility,type,dateRange,groupBy}) => (
    
    resultSet.loadResponse.data.map(s => {
      console.log(s[resultSet.loadResponse.query.dimensions[0]])
      console.log({resultSet})
      let group=resultSet.loadResponse.query.filters[3]["dimension"];
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      let inspectDate=s[resultSet.loadResponse.query.dimensions[3]];
      let InspDate = new Date(inspectDate).getDate()+"-"+monthNames[new Date(inspectDate).getMonth()]+"-"+new Date(inspectDate).getFullYear();
      return(
      <Col span={24} md={12} lg={12} xs={24}>
      <Link style={{textDecoration:"none"}} to={`/inspection-task-detail/ins_number=${s[resultSet.loadResponse.query.dimensions[2]]}`}>
      <DashboardItem >
      <Row type="flex" justify="left" align="middle" style={{ height: "100%" }}>
      <Col className="position-content">

      <div className='big-number primary-color'> 
      {s[resultSet.loadResponse.query.dimensions[2]] != null ? s[resultSet.loadResponse.query.dimensions[2]] : "None1"}
        </div>

      <div className='big-number_2'>
        <span className="primary-color"> Inspection Date : </span> {InspDate} 
        &nbsp;&nbsp; | &nbsp;&nbsp;  
        <span className="primary-color"> 
        {group == 'Inspection.bay' ? s['Inspection.inspection_type'] : s[resultSet.loadResponse.query.dimensions[0]]}
        </span>
      </div>


      <span class="task-list-btn">Enter Inspection</span>


      
      {/* <div className='big-number_3'>
        {group == 'Inspection.bay' ? s['Inspection.inspection_type'] : s[resultSet.loadResponse.query.dimensions[0]]}
      </div> */}


      </Col>
      </Row>
      <Row type="flex" justify="center" align="middle" style={{ height: "100%" }}>
      <Col>
             
      </Col>
      </Row></DashboardItem>
            </Link>
        </Col>)
    })
  ),
  accordion: ({ resultSet,facility,report,viewBy }) => (
    resultSet.loadResponse.data.map(s => {
    console.log(resultSet.seriesNames()[0].key)
    let showVal = viewBy == "customers" || viewBy == "scheduled-bay" ? 6 : 5;
    if(report=='missed-po-order'){
      return (<Collapse>
    <Panel header={<div><div style={{fontSize:"20px", fontWeight:"bold"}}>{s["MissedSOrder.bpname"] != null ? s["MissedSOrder.bpname"] : "None"}</div>
        <div>{s["MissedSOrder.sop_requested_date"].split('T').join(' ').substr(0, 16)+" "}
        &nbsp;&nbsp;|&nbsp;&nbsp; {s["MissedSOrder.documentno"] != null ? s["MissedSOrder.documentno"] : "None"}</div></div>} key="1"  extra={genExtra(s["MissedSOrder.corder_id"])}>
    <Row className="missHeader">
      <Col span={6}>Container Type</Col>
      <Col span={6}>Requested Date</Col>
      <Col span={6}>PO Reference</Col>
      <Col span={6}>Grand Total</Col>
    </Row>
    <Row style={{padding:"10px 20px"}}>
      <Col span={6}>{s["MissedSOrder.container_type_name"]}</Col>
      <Col span={6}>{s["MissedSOrder.sop_requested_date"].split('T').join(' ').substr(0, 16)}</Col>
      <Col span={6}>{s["MissedSOrder.po_reference"]}</Col>
      <Col span={6}>{s["MissedSOrder.grandtotal"]}</Col>
    </Row>
    </Panel>
  </Collapse>)
    }else if(report=='pending-orders'){
      return (<Collapse>
        <Panel header={<div><div style={{fontSize:"20px", fontWeight:"bold"}}>{viewBy == "scheduled-bay" ? (s["CBpartner.name"] != null ? s["CBpartner.name"] : "None"): viewBy == "customers" ? (s["PendingWorkOrder.scheduled_bay"] != null ? s["PendingWorkOrder.scheduled_bay"] : "None") : s["CBpartner.name"] != null ? s["CBpartner.name"] : s["PendingWorkOrder.scheduled_bay"] != null ? s["PendingWorkOrder.scheduled_bay"] : "None"}</div>
        <div>{s["PendingWorkOrder.sop_requested_date"].split('T').join(' ').substr(0, 16)+" "}
        &nbsp;&nbsp;|&nbsp;&nbsp; {s["PendingWorkOrder.documentno"] != null ? s["PendingWorkOrder.documentno"] : (s["PendingWorkOrder.sdocumentno"] != null ? s["PendingWorkOrder.sdocumentno"] : "")}</div></div>} key="1"  extra={pendRedir(s["PendingWorkOrder.documentno"],s["PendingWorkOrder.sdocumentno"],s["PendingWorkOrder.c_order_id"])}>
        <Row className="missHeader">
          <Col span={showVal} style={{display:(viewBy == "customers" ? "none" : "block")}}>Customer Name</Col>
          <Col span={showVal} style={{display:(viewBy == "scheduled-bay" ? "none" : "block")}}>Scheduled Bay</Col>
          <Col span={showVal}>Requested Date</Col>
          <Col span={showVal}>Service Plan Name</Col>
          <Col span={viewBy == "customers" || viewBy == "scheduled-bay" ? 6 : 4}>Grand Total</Col>
        </Row>
        <Row style={{padding:"10px 20px"}}>
          <Col span={showVal} style={{display:(viewBy == "customers" ? "none" : "block")}}>{s["CBpartner.name"]}</Col>
          <Col span={showVal} style={{display:(viewBy == "scheduled-bay" ? "none" : "block")}}>{s["PendingWorkOrder.scheduled_bay"]}</Col>
          <Col span={showVal}>{s["PendingWorkOrder.sop_requested_date"].split('T').join(' ').substr(0, 16)}</Col>
          <Col span={showVal}>{s["ContainerPlan.contmng_plan_name"]}</Col>
          <Col span={viewBy == "customers" || viewBy == "scheduled-bay" ? 6 : 4}>{s["PendingWorkOrder.grandtotal"]}</Col>
        </Row>
        </Panel>
      </Collapse>)
    }else if(report=='delayed-orders'){
      return (<Collapse>
        <Panel header={<div><div style={{fontSize:"20px", fontWeight:"bold"}}>{viewBy == "scheduled-bay" ? (s["CBpartner.name"] != null ? s["CBpartner.name"] : "None") : viewBy == "customers" ? (s["DelayedSOrder.scheduled_bay"] != null ? s["DelayedSOrder.scheduled_bay"] : "None") : s["CBpartner.name"] != null ? s["CBpartner.name"] : s["DelayedSOrder.scheduled_bay"] != null ? s["DelayedSOrder.scheduled_bay"] : "None"}</div>
        <div>{s["DelayedSOrder.sop_requested_date"].split('T').join(' ').substr(0, 16)+" "}
        &nbsp;&nbsp;|&nbsp;&nbsp; {s["DelayedSOrder.wo_documentno"] != null ? s["DelayedSOrder.wo_documentno"] : (s["DelayedSOrder.contmng_workorder_id"] != null ? s["DelayedSOrder.contmng_workorder_id"] : (s["DelayedSOrder.documentno"] != null ? s["DelayedSOrder.documentno"] : ""))}</div></div>} key="1"  extra={delayRedir(s["DelayedSOrder.wo_documentno"],s["DelayedSOrder.contmng_workorder_id"],s["DelayedSOrder.corder_id"])}>
        <Row className="missHeader">
          <Col span={showVal} style={{display:(viewBy == "customers" ? "none" : "block")}}>Customer Name</Col>
          <Col span={showVal} style={{display:(viewBy == "scheduled-bay" ? "none" : "block")}}>Scheduled Bay</Col>
          <Col span={showVal}>Requested Date</Col>
          <Col span={showVal}>Service Plan Name</Col>
          <Col span={viewBy == "customers" || viewBy == "scheduled-bay" ? 6 : 4}>Grand Total</Col>
        </Row>
        <Row style={{padding:"10px 20px"}}>
          <Col span={showVal} style={{display:(viewBy == "customers" ? "none" : "block")}}>{s["CBpartner.name"]}</Col>
          <Col span={showVal} style={{display:(viewBy == "scheduled-bay" ? "none" : "block")}}>{s["DelayedSOrder.scheduled_bay"]}</Col>
          <Col span={showVal}>{s["DelayedSOrder.sop_requested_date"].split('T').join(' ').substr(0, 16)}</Col>
          <Col span={showVal}>{s["ContainerPlan.contmng_plan_name"]}</Col>
          <Col span={viewBy == "customers" || viewBy == "scheduled-bay" ? 6 : 4}>{s["DelayedSOrder.grandtotal"]}</Col>
        </Row>
        </Panel>
      </Collapse>)
    }
    })
  )
};
const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
  .map(key => ({
    [key]: React.memo(TypeToChartComponent[key])
  }))
  .reduce((a, b) => ({ ...a, ...b }));

const renderChart = Component => ({ resultSet, error }) => (facilityVal,typeVal,rangeVal,groupVal)  =>
  (resultSet && <Component resultSet={resultSet} facility={facilityVal} type={typeVal} dateRange={rangeVal} groupBy={groupVal} />) ||
  (error && error.toString()) || <Spin />;

const ChartRenderer = ({ vizState,facilityVal,typeVal,rangeVal,groupVal }) => {
  const { query, chartType } = vizState;
  console.log(typeVal);
  const component = TypeToMemoChartComponent[chartType];
  const renderProps = useCubeQuery(query);
  return component && renderChart(component)(renderProps)(facilityVal,typeVal,rangeVal,groupVal);
};

ChartRenderer.propTypes = {
  vizState: PropTypes.object,
  cubejsApi: PropTypes.object
};
ChartRenderer.defaultProps = {
  vizState: {},
  cubejsApi: null
};
export default ChartRenderer;

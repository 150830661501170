import React, { PureComponent } from 'react';
import { Row, Col, Card, PageHeader, Button } from "antd";
import Grid from "@material-ui/core/Grid";
import ChartRenderer from "../components/ChartRendererInspection";
import Dashboard from "../components/Dashboard";

import {
  Link,
} from "react-router-dom";

// import Tooltip from '@bit/recharts.recharts.tooltip';
import 'antd/dist/antd.css';

class InspectionPage extends PureComponent {
  constructor(props) {
    super(props)

    var current = new Date;
    var firstDayWeek = new Date(current.setDate(current.getDate() - current.getDay() + (current.getDay() == 0 ? -6 : 1)));
    var start = (firstDayWeek.getYear() + 1900) + '-' + (firstDayWeek.getMonth() < 9 ? '0' + (firstDayWeek.getMonth() + 1) : (firstDayWeek.getMonth() + 1)) + '-' + (firstDayWeek.getDate() < 10 ? '0' + firstDayWeek.getDate() : firstDayWeek.getDate());

    var lastDayWeek = new Date(firstDayWeek.getTime() + (6 * 24 * 60 * 60 * 1000));
    var last = (lastDayWeek.getYear() + 1900) + '-' + (lastDayWeek.getMonth() < 9 ? '0' + (lastDayWeek.getMonth() + 1) : (lastDayWeek.getMonth() + 1)) + '-' + (lastDayWeek.getDate() < 10 ? '0' + lastDayWeek.getDate() : lastDayWeek.getDate());
    var dateArr = [];
    dateArr.push(start);
    dateArr.push(last);

    this.state = {
      facilityList: ['101'],
      type: "notSet",
      groupBy: "Inspection.inspection_type",
      rangeValue: {
        "dimension": "Inspection.startdate",
        "operator": "inDateRange",
        "values": dateArr
      },
      groupActive: "Inspection Type",
      typeActive: "pending",
      rangeActive: "this week",
      disableComplete: false,
      disableNextWeek: false,
      size: 'large'
    };
    this.style = {
      chips: {
        background: "red"
      },
      searchBox: {
        border: "none",
        "border-bottom": "1px solid #001529",
        "border-radius": "0px"
      }
    };
  }

  ChangeGroupBy = e => {
    if (e.currentTarget.getAttribute('id') === 'inspectType') {
      this.setState({
        groupActive: "Inspection Type",
        groupBy: "Inspection.inspection_type"
      });
    } else if (e.currentTarget.getAttribute('id') === 'bay') {
      this.setState({
        groupActive: "Bay",
        groupBy: "Inspection.bay"
      });
    }
  }

  ChangeType = e => {
    let disable = this.state.rangeActive === "next week" ? true : false;
    if (e.currentTarget.getAttribute('id') === 'pending') {
      this.setState({
        typeActive: "pending",
        type: "notSet",
        disableNextWeek: disable,
        disableComplete: false
      });
    } else if (e.currentTarget.getAttribute('id') === 'completed') {
      this.setState({
        typeActive: "completed",
        type: "set",
        disableNextWeek: true,
        disableComplete: false
      });
    }
  }

  ChangeRange = e => {
    let disable = this.state.typeActive === "completed" ? true : false;
    if (e.currentTarget.getAttribute('id') === 'prev') {
      this.setState({
        rangeActive: "prev week",
        disableNextWeek: disable,
        disableComplete: false
      });
      this.getFirstWeekDate();
    } else if (e.currentTarget.getAttribute('id') === 'current') {
      this.setState({
        rangeActive: "this week",
        disableNextWeek: disable,
        disableComplete: false
      });
      this.getCurrentWeekDate();
    } else if (e.currentTarget.getAttribute('id') === 'next') {
      this.setState({
        rangeActive: "next week",
        disableNextWeek: false,
        disableComplete: true
      });
      this.getLastWeekDate();
    }
  }

  getCurrentWeekDate = e => {
    var current = new Date;
    var firstDayWeek = new Date(current.setDate(current.getDate() - current.getDay() + (current.getDay() == 0 ? -6 : 1)));
    var start = (firstDayWeek.getYear() + 1900) + '-' + (firstDayWeek.getMonth() < 9 ? '0' + (firstDayWeek.getMonth() + 1) : (firstDayWeek.getMonth() + 1)) + '-' + (firstDayWeek.getDate() < 10 ? '0' + firstDayWeek.getDate() : firstDayWeek.getDate());

    var lastDayWeek = new Date(firstDayWeek.getTime() + (6 * 24 * 60 * 60 * 1000));
    var last = (lastDayWeek.getYear() + 1900) + '-' + (lastDayWeek.getMonth() < 9 ? '0' + (lastDayWeek.getMonth() + 1) : (lastDayWeek.getMonth() + 1)) + '-' + (lastDayWeek.getDate() < 10 ? '0' + lastDayWeek.getDate() : lastDayWeek.getDate());
    var dateArr = [];
    dateArr.push(start);
    dateArr.push(last);
    this.setState({
      rangeValue: {
        "dimension": "Inspection.startdate",
        "operator": "inDateRange",
        "values": dateArr
      }
    });
  }

  getFirstWeekDate = e => {
    var current = new Date;
    var firstDayWeek = new Date(current.setDate(current.getDate() - current.getDay() + (current.getDay() == 0 ? -6 : 1)));
    var start = (firstDayWeek.getYear() + 1900) + '-' + (firstDayWeek.getMonth() < 9 ? '0' + (firstDayWeek.getMonth() + 1) : (firstDayWeek.getMonth() + 1)) + '-' + (firstDayWeek.getDate() < 10 ? '0' + firstDayWeek.getDate() : firstDayWeek.getDate());

    //var lastDayWeek = new Date(firstDayWeek.getTime() + (6 * 24 * 60 * 60 * 1000));
    //var last = (lastDayWeek.getYear()+1900)+'-'+(lastDayWeek.getMonth()<9 ? '0'+(lastDayWeek.getMonth()+1) : (lastDayWeek.getMonth()+1))+'-'+(lastDayWeek.getDate()<10 ? '0'+lastDayWeek.getDate() : lastDayWeek.getDate());
    var dateArr = [];
    dateArr.push(start);
    this.setState({
      rangeValue: {
        "dimension": "Inspection.startdate",
        "operator": "beforeDate",
        "values": dateArr
      }
    });
  } 
  getLastWeekDate = e => {
    var current = new Date;
    var firstDayWeek = new Date(current.setDate(current.getDate() - current.getDay() + (current.getDay() == 0 ? -6 : 1)));
    var start = (firstDayWeek.getYear() + 1900) + '-' + (firstDayWeek.getMonth() < 9 ? '0' + (firstDayWeek.getMonth() + 1) : (firstDayWeek.getMonth() + 1)) + '-' + (firstDayWeek.getDate() < 10 ? '0' + firstDayWeek.getDate() : firstDayWeek.getDate());

    var lastDayWeek = new Date(firstDayWeek.getTime() + (6 * 24 * 60 * 60 * 1000));
    var last = (lastDayWeek.getYear() + 1900) + '-' + (lastDayWeek.getMonth() < 9 ? '0' + (lastDayWeek.getMonth() + 1) : (lastDayWeek.getMonth() + 1)) + '-' + (lastDayWeek.getDate() < 10 ? '0' + lastDayWeek.getDate() : lastDayWeek.getDate());
    var dateArr = [];
    dateArr.push(last);
    this.setState({
      rangeValue: {
        "dimension": "Inspection.startdate",
        "operator": "afterDate",
        "values": dateArr
      }
    });
  } 
  render() {
    let managerFacilityFilter = [{
      "dimension": "AdOrg.facility",
      "operator": "equals",
      "values": this.state.facilityList
    },
    {
      "dimension": "Inspection.enddate",
      "operator": this.state.type,
    },
    this.state.rangeValue
    ]; 
    const DashboardItemsTop = [
      {
        id: 0,
        name: "Inspection",
        vizState: {
          query: {
            "measures": [
              "Inspection.count"
            ],
            "filters": managerFacilityFilter,
            "dimensions": [
              this.state.groupBy
            ]
          },
          chartType: "widget"
        },
        grid: 12
      }
    ];
    return (
      <Dashboard> 
        <div className="widget-header">
          <div className="show-mobile full-width">
            <span className={this.state.typeActive === "pending" ? 'tab-btn view-active' : 'tab-btn'} id="pending" onClick={this.ChangeType}>Pending</span>
            <span className={this.state.disableComplete ? "tab-btn disable-btn" : this.state.typeActive === "completed" ? 'tab-btn view-active' : 'tab-btn'} id="completed"
              disabled={this.state.disableComplete} onClick={this.ChangeType}>Completed</span>
          </div> 
          <div className="show-mobile full-width border-bottom widget-space" style={{ marginBottom: "0px" }}>
            <div>
              <span className={this.state.rangeActive === "prev week" ? 'filter-btn view-active' : 'filter-btn'} id="prev" onClick={this.ChangeRange}>Past</span>
              <span className={this.state.rangeActive === "this week" ? 'filter-btn view-active' : 'filter-btn'} id="current" onClick={this.ChangeRange}>This Week</span>
              <span className={this.state.disableNextWeek ? "filter-btn disable-btn" : this.state.rangeActive === "next week" ? 'filter-btn view-active' : 'filter-btn'}
                id="next" disabled={this.state.disableNextWeek}
                onClick={this.ChangeRange}>Upcoming</span>
            </div>
          </div> 
          <div className="show-mobile full-width border-bottom widget-space" style={{ marginBottom: "0px" }}>
            <div><span  className={this.state.groupActive === "Inspection Type" ? 'filter-btn adjust view-active' : 'filter-btn adjust'} id="inspectType"
              onClick={this.ChangeGroupBy}>Ins. Type</span>
              <span  className={this.state.groupActive === "Bay" ? 'filter-btn adjust view-active' : 'filter-btn adjust'} id="bay" onClick={this.ChangeGroupBy}>Bay</span>
            </div>
          </div> 
        </div> 
        <Grid xs={24} lg={24} container>
          <Col xs={24} lg={8} span={8}>
            <div style={{ marginBottom: "20px" }}>
              <h3 class="mob-filter heading show-desktop" style={{ color: "#707070", marginTop: "5px", marginRight: "20px", fontSize: "20px" }}> Status : </h3>
              <div className="show-desktop">
                <button type="button" className={this.state.typeActive === "pending" ? 'scheduled-btn view-active' : 'scheduled-btn'} id="pending" onClick={this.ChangeType}>Pending</button>
                <button type="button" className={this.state.disableComplete ? "plan-btn disable-btn" : this.state.typeActive === "completed" ? 'plan-btn view-active' : 'plan-btn'} id="completed" disabled={this.state.disableComplete} onClick={this.ChangeType}>Completed</button>
              </div> 
            </div>
          </Col>
          <Col xs={24} lg={8} span={8}> 
            <div className="show-desktop" style={{ marginBottom: "20px" }}>
              <h3 class="mob-filter heading" style={{ color: "#707070", marginTop: "5px", marginRight: "20px", fontSize: "20px" }}>Date Range :
					</h3>
              <div>
                <button type="button" className={this.state.rangeActive === "prev week" ? 'scheduled-btn view-active' : 'scheduled-btn'} id="prev" onClick={this.ChangeRange}>Past</button>
                <button type="button" className={this.state.rangeActive === "this week" ? 'scheduled-btn view-active' : 'scheduled-btn'} id="current" onClick={this.ChangeRange}>This Week</button>
                <button type="button" className={this.state.disableNextWeek ? "plan-btn disable-btn" : this.state.rangeActive === "next week" ? 'plan-btn view-active' : 'plan-btn'} id="next" disabled={this.state.disableNextWeek} onClick={this.ChangeRange}>Upcoming</button>
              </div>
            </div> 
          </Col>
          <Col xs={24} lg={8} span={8}>
            <div className="show-desktop" style={{ marginBottom: "20px" }}>
              <h3 class="mob-filter heading" style={{ color: "#707070", marginTop: "5px", marginRight: "20px", fontSize: "20px" }}>View By :
					</h3>
              <div><button type="button" className={this.state.groupActive === "Inspection Type" ? 'scheduled-btn view-active' : 'scheduled-btn'} id="inspectType" onClick={this.ChangeGroupBy}>Inspection Type</button>
                <button type="button" className={this.state.groupActive === "Bay" ? 'plan-btn view-active' : 'plan-btn'} id="bay" onClick={this.ChangeGroupBy}>Bay</button>
              </div>
            </div>
          </Col>
        </Grid> 
        <Grid className='top-widgets' xs={24} lg={24} container spacing={0} style={{ padding: 0 }} justify="left" alignItems="flex-start">
          <ChartRenderer vizState={DashboardItemsTop[0].vizState} facilityVal={this.state.facilityList[0]} typeVal={this.state.typeActive} rangeVal={this.state.rangeActive} groupVal={this.state.groupActive} />
        </Grid>
      </Dashboard>
    ) 
  }
}


export default InspectionPage;
